@import "base";

a,
button {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

a,
a:hover {
  text-decoration: none !important;
}

ul {
  list-style-type: none !important;
  padding: 0;
  margin: 0;
}


label {
  margin-bottom: 0;
}

.scroll-to-top {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem 2rem 0 0;
  background: $accent-green;
  display: block;
  text-align: center;
  padding-top: 0.8rem;
  font-size: 2.2rem;
  color: $secondary-white;
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  z-index: 99999999;
}

.scroll-to-top:hover {
  background: $accent-green;
  color: $font-colour;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

/* nav area */

.web-header {
  /*     position: fixed;
    width: 100%; */
  padding: 2.5rem 0 1.5rem 0;
  background-color: $background-black;
  z-index: 1111111111111111;
  position: relative;
}

.web-header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.web-header .web-menu-item ul li a {
  font-size: 1.6rem;
  font-weight: 400;
  color: $font-colour;
  display: inline-block;
  padding: 1rem 5rem;
  text-transform: uppercase;
  transition: 0.3s;
  position: relative;
}

.web-menu-item ul li a:hover {
  color: $accent-green;
}

.web-menu-item ul li a::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: $accent-green;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -3rem;
  border-radius: 50%;
  opacity: 0;
  transition: 0.2s;
}

.web-menu-item ul li a:hover::before {
  opacity: 1;
}

.web-menu-bar {
  display: none;
}

.web-menu-bar a {
  font-size: 3rem;
  color: $accent-green;
  cursor: pointer;
}

.web-h-contact {
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
}

.web-h-contact:hover {
  color: $font-colour !important;
  background-color: $hover-btn-green;
}

.web-menu-logo {
  position: relative;
}

.web-menu-logo {
  position: absolute;
  top: 2rem;
  left: 0;
}

.web-menu-logo img {
  max-width: 15rem;
}

.logos {
  visibility: hidden;
}

#active {
  color: $accent-green;
}

#active::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: $accent-green;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -25px;
  border-radius: 50%;
  opacity: 1;
  transition: 0.2s;
  transform: translate(-50%, -50%);
}

.active {
  color: $accent-green !important;
}

.web-h-contact::before {
  content: "";
  display: none !important;
}

.web-menu-item ul li a.active:before,
.web-mobile-menu-item ul li a.active:before {
  opacity: 1;
}

.web-header .home-before-line {
  position: relative;
}

.web-header .home-before-line::before {
  content: "";
  width: 23rem;
  height: 0.1rem;
  background-color: $secondary-white;
  display: block;
  position: absolute;
  top: 3.3rem;
  left: 38rem;
}

.web-header .home-before-line::after {
  content: "";
  width: 15rem;
  height: 0.1rem;
  background-color: $secondary-white;
  display: block;
  position: absolute;
  top: 4.3rem;
  left: 38rem;
}

.web-header .contact-before-line {
  position: relative;
}

.web-header .contact-before-line::before {
  content: "";
  width: 23rem;
  height: 0.1rem;
  background-color: $secondary-white;
  display: block;
  position: absolute;
  top: 3.3rem;
  left: 35rem;
}

.web-header .contact-before-line::after {
  content: "";
  width: 15rem;
  height: 0.1rem;
  background-color: $secondary-white;
  display: block;
  position: absolute;
  top: 4.3rem;
  left: 35rem;
}

.web-header .price-before-line {
  position: relative;
}

.web-header .price-before-line::before {
  content: "";
  width: 23rem;
  height: 0.1rem;
  background-color: $secondary-white;
  display: block;
  position: absolute;
  top: 3.5rem;
  left: 18.5rem;
}

.web-header .price-before-line::after {
  content: "";
  width: 15rem;
  height: 0.1rem;
  background-color: $secondary-white;
  display: block;
  position: absolute;
  top: 4.5rem;
  left: 18.5rem;
}

.web-header .bar {
  width: 3rem;
  cursor: pointer;
}

.web-header .bar span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: $accent-green;
  border-radius: 1rem;
  margin: 0.6rem 0;
}