@import "base";



.web-Pricing-hero h2:last-of-type {
  color: $accent-green !important;
}

.web-aboutus-hero {
  background-image: url(../assets/websiteimg/contact-bg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  height: calc( 100vh - 90px);
}

.contact-left-text h2 {
  color: $third-white !important;
  text-transform: capitalize !important;
  margin-bottom: 11rem !important;
}

.contact-left-text h2 span {
  color: $font-colour;
  text-transform: capitalize !important;
}

.culture-dating-area {
  padding: 10rem 0;
}

.cul-dat-title h3 {
  font-size: 5rem !important;
  font-weight: 900 !important;
  color: $accent-green;
  text-align: center;
}

.cul-dat-title h2 {
  font-size: 5.5rem !important;
  font-weight: 900 !important;
  color: $font-colour;
  text-align: center;
  text-transform: uppercase;
  margin: 4rem 0rem !important;
}

.cul-dat-title p {
  font-size: 1.6rem;
  font-weight: 400;
  color: $font-colour;
  text-align: center;
  margin: 6rem 0rem !important;
}

.dating-item-area {
  text-align: center;
  padding: 5rem 0;
}

.dating-item {
  margin-bottom: 2rem;
}

.contact-need-area {
  padding: 12rem 0 2rem 0;
  background-image: url(../assets/websiteimg/contact-need-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 886px;
}

.contact-need-title h2 {
  text-align: left !important;
  font-size: 5.5rem !important;
  margin: 3rem 0 !important;
  text-transform: uppercase;
  color: white;
  line-height: 5.5rem !important;
  font-weight: 900 !important;

}

.contact-need-title h3 {
  text-align: left !important;  
  font-size: 5rem !important;
  color: $accent-green;
  font-weight: 900 !important;
}

.approach-area {
  padding: 0 0 7rem 0;
}

.approach-left {
  padding-top: 6rem !important;
  position: relative !important;
}

.approach-left h5 {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  font-weight: 700;
  color: $accent-green;
  margin-bottom: 1.5rem;
  text-align: right;
  margin-top: 1.5rem;
}

.approach-left p {
  font-size: 1.5rem;
  line-height: 2.5rem !important;
  font-weight: 500;
  text-align: right;
  margin-bottom: 4rem;
  color: $font-colour;
  margin: 3rem 0rem !important;
}

.time-in-1 {
  position: absolute;
  right: -16rem ;
  top: -2rem;
  z-index: 1;
}

.time-in-m-2 {
  top: 42px;
}

.approach-right {
  position: relative;
  padding-top: 6rem;
}

.approach-right h5 {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  font-weight: 700;
  color: $accent-green;
  margin-bottom: 1.5rem;
  text-align: left;
  margin-top: 1.5rem;
}

.approach-right p {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  margin: 3rem 0rem !important;
  color: $font-colour;
  line-height: 2.5rem;
}

.time-in-3 {
  position: absolute;
  left: -16rem;
  top: -2rem;
}

.time-in-m-4 {
  top: 42px;
}

.mobile-timeline-img {
  display: none;
  text-align: center;
}

.mobile-timeline-img img {
  width: 12rem;
}