@import "base";

.web-hero-area {
  background-image: linear-gradient(90deg, rgba(0,0,0,0.7) 50%, rgba(255,255,255,0) 100%), url(../assets/websiteimg/hero_banner.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  padding: 12rem 0 30rem 0;
  position: relative;
  height: calc(100vh - 90px);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

.web-hero-left-text {
  h2 {
    font-size: 5.5rem;
    font-weight: 900;
    color: $font-colour;
    margin-bottom: 5rem !important;
    line-height: 6rem !important;
  }

  .white-text {
    color: $font-colour;
    text-transform: capitalize;
    margin-left: 0.5rem;
  }
}

.web-hero-left-text h2:first-of-type {
  font-size: 5.5rem;
  font-weight: 900;
  color: $font-colour;
  margin-bottom: 1rem !important;
}

.web-hero-left-text h2:last-of-type {
  font-size: 5.5rem;
  font-weight: 900;
  color: $font-colour;
  margin-bottom: 5rem !important;
  color: $accent-green;
}

.web-hero-left-text h2 span {
  color: $accent-green;
  text-transform: uppercase;
}

.web-hero-left-text p {
  font-size: 2.4rem;
  font-weight: 600;
  color: $font-colour;
  margin-bottom: 5rem !important;
  line-height: 3.4rem;
}

.web-hero-left-text a {
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: $background-black;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.web-hero-left-text a:hover {
  background-color: $hover-btn-green;
}

.scroll {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.web-hero-right-item {
  position: relative !important;
}

/* ====offcanvas===== */

.offcanvas-body {
  background-color: $background-black;
  color: $font-colour;
}

.offcanvas-header {
  background-color: $background-green;
  z-index: 1111;
}

.offcanvas-header h5 img {
  max-width: 20rem;
}

.clloss-x {
  font-size: 2.5rem;
  cursor: pointer;
  color: $font-colour;
}

.clloss-x:hover {
  color: $font-colour !important;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 33.3rem;
  z-index: 11111111111111;
}

.web-mobile-menu-item ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  color: $font-colour;
  display: inline-block;
  padding: 1rem 3rem;
  transition: 0.2s;
}

.web-mobile-menu-item ul li a:hover {
  color: $accent-green !important;
}

.web-mobile-login a {
  display: block;
  text-align: center;
  background-position: 35% 50%;
  z-index: 1111;
  margin-top: 1rem;
}

.web-card-item {
  padding: 3.6rem 2.6rem;
  border-radius: 2rem;
  background-color: $third-black;
}

.web-card-area {
  padding: 13rem 0;
  background-color: $background-black;
}
.integrated-card-area {
  padding: 8rem 0;
  background-color: $background-black;
}

.web-card-item {
  padding: 3.6rem 2.6rem;
  border-radius: 2rem;
  background-color: $third-black;
}

.web-card-item img {
  margin-bottom: 1.5rem;
}

.web-card-item h2 {
  font-size: 2.6rem;
  font-weight: 700;
  color: $accent-green;
  margin-bottom: 2rem;
}

.web-card-item p {
  font-size: 1.6rem;
  font-weight: 600;
  color: $font-colour;
}

.web-h-contact:hover {
  color: $font-colour !important;
  background-color: $hover-btn-green;
}

.web-business-area {
  padding: 10rem 0 30rem 0;
  background-image: url(../assets/websiteimg/business-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.web-business-title {
  text-align: center;
}

.web-business-title h3 {
  font-size: 5rem !important;
  font-weight: 900 !important;
  color: $accent-green;
}

.web-business-title h2 {
  font-size: 5.5rem !important;
  font-weight: 900 !important;
  color: $font-colour;
  margin: 4rem 0 !important;
  text-transform: uppercase;
  line-height: 5.5rem !important;
}

.web-business-title a {
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: $background-black;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.web-business-title a:hover {
  background-color: $hover-btn-green;
}

.web-business-dashboard {
  padding: 10rem 0;
}

.web-dashboard-img {
  text-align: center;
  margin-top: -35rem;
}

.web-client-like-area {
  padding: 10rem 0;
  position: relative;
  z-index: 111;
  overflow: hidden;
}

.web-client-like-title p {
  font-size: 4.2rem;
  font-weight: 700;
  color: $font-colour;
  margin-bottom: 5rem;
  line-height: 1.5;
}

.web-client-like-title p span {
  color: $accent-green;
}

.web-client-box-item {
  background-image: url(../assets/websiteimg/s.webp);
  background-repeat: no-repeat;
  padding: 15rem 3.8rem;
  background-position: 100% 100%;
  background-size: 100% 100%;
  text-align: center;
  height: 100%;
}

.web-client-box-item h3 {
  font-size: 2.6rem;
  font-weight: 700;
  color: $accent-green;
  margin-bottom: 2rem;
}

.web-client-box-item p {
  font-size: 1.6rem;
  font-weight: 600;
  color: $font-colour;
}

.web-client-like-area::after {
  content: "";
  width: 100rem;
  height: 60rem;
  display: block;
  background-image: url(../assets/websiteimg/client-bg.webp);
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -111;
}

.web-customer-area {
  padding: 12rem 0;
  background-image: url(../assets/websiteimg/coustomar-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.web-customer-right h2 {
  font-size: 5rem !important;
  font-weight: 700;
  color: $font-colour;
  margin-bottom: 3.6rem !important;
}

.web-customer-right h2 span {
  color: $accent-green;
  text-transform: uppercase;
  font-weight: 900 !important;
  line-height: 6rem;
}

.web-customer-right p {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3rem;
  color: $font-colour;
  margin-bottom: 5rem !important;
}

.web-customer-right ul {
  padding: 1rem 0;
  border-left: 0.2rem solid $secondary-black;
  margin-bottom: 4rem;
}

.web-customer-right ul li {
  font-size: 1.6rem;
  font-weight: 600;
  color: $secondary-white;
  margin-left: 3rem;
  position: relative;
  /* line-height: 0px; */
  margin-bottom: 2rem;
}

.web-customer-right ul li:nth-child(3) {
  margin-bottom: 0 !important;
}

.web-customer-right ul li::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: $accent-green;
  outline: 0.5rem solid $outline-green;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0.5rem;
  left: -3.6rem;
}

.web-customer-right a {
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: $background-black;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.web-customer-right a:hover {
  background-color: $hover-btn-green;
}
@media screen and (max-width: 767.5px) {
  .web-hero-right-item {
    display: none;
  }
  .web-hero-area {
    padding: 12rem 0 3rem 0;
  }
  .web-card-area {
    padding: 5rem 0;
  }
  .integrated-card-area {
    padding: 5rem 0;
  }
}
