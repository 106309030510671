/*
====================================
Medium Screen - Others
====================================
*/

@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .container {
    max-width: 1120px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-img img {
    max-width: 372px;
  }
  /*=== Features Pricing page start=== */
  .price-box p span {
    font-size: 54px;
  }
  .price-box {
    padding: 111px 20px 43px 20px;
  }

  .client-like-title p br {
    display: none;
  }

  .approach-area {
    padding-top: 0;
  }

  body {
    overflow-x: hidden;
  }
}

/*
   ====================================
   Small Screen - Tablate
   ====================================
   */

@media screen and (min-width: 768px) and (max-width: 991px) {
  .menu-bar {
    display: block;
  }
  .menu-item {
    display: none;
  }
  .hero-area {
    background-image: url(../assets/websiteimg/hero-bg.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-position: top left;
    padding: 199px 0 157px 0;
    position: relative;
    background-size: cover;
  }
  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .container {
    padding: 0 15px;
  }
  .hero-img {
    position: inherit;
    margin-top: 44px;
  }
  .scroll {
    position: absolute;
    bottom: -24px;
  }
  .card-area {
    padding: 94px 0 60px;
    background-color: #000000;
  }
  .card-item {
    padding: 36px 26px;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 30px;
  }
  .card-item p br {
    display: none;
  }
  .business-area {
    padding: 70px 0 118px 0;
  }
  .business-title h3 {
    font-size: 58px;
  }
  .business-title h2 {
    font-size: 48px;
  }
  .business-dashboard {
    padding: 100px 0 61px 0;
  }
  .dashbord-img {
    text-align: center;
    margin-top: -183px;
  }
  .client-like-title p br {
    display: none;
  }
  .client-like-title p {
    font-size: 21px;
  }
  .client-like-area::after {
    content: "";
    width: 1000px;
    height: 600px;
    display: block;
    background-image: url(../assets/websiteimg/client-bg.webp);
    background-repeat: no-repeat;
    background-position: top;
    left: left;
    position: absolute;
    top: 0;
    right: -81px;
    z-index: -126;
  }
  .client-box-item {
    margin-bottom: 30px;
  }
  .coustomer-right h2 {
    font-size: 34px;
  }
  .coustomer-left {
    margin-bottom: 26px;
  }
  .client-like-area {
    padding: 60px 0;
  }
  .customer-area {
    padding: 70px 0;
  }
  .footer-item {
    margin-bottom: 30px;
  }
  .copyright-text {
    border-top: 2px solid #78ef30;
    margin-top: 30px;
    padding: 35px 0;
    display: block;
    text-align: center;
  }
  .copyright-text p {
    margin-bottom: 20px;
  }
  /*=== Features Pricing page start=== */
  .priceing-area {
    padding: 290px 0 70px 0;
    background-image: url(../assets/websiteimg/price-list-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 60%;
    background-position: bottom;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .price-box p {
    font-size: 13px;
    font-weight: 600;
    color: #78ef30;
    text-transform: uppercase;
  }
  .price-box p span {
    font-size: 55px;
  }
  .map-item-bg {
    padding: 20px 7px;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-title h4 {
    font-size: 36px;
  }
  .get-In-area {
    padding: 56px 0;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .map-item {
    background-image: url(../assets/websiteimg/map-item.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 93px 35px;
  }
  .mobile-menu-item ul li a {
    display: block;
    margin-top: 15px;
  }
  .h-contact {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .home-befor-line::before {
    top: 24px;
    left: 250px;
  }
  .home-befor-line::after {
    top: 30px;
    left: 250px;
  }
  .price-befor-line::before {
    top: 24px;
    left: 130px;
  }
  .price-befor-line::after {
    top: 30px;
    left: 130px;
  }
  .contact-befor-line::before {
    top: 24px;
    left: 230px;
  }
  .contact-befor-line::after {
    top: 30px;
    left: 230px;
  }
  .footer-item ul li {
    color: #fff;
    padding: 10px 0;
    font-size: 12px;
  }
  // =====
  .contact-hero {
    padding: 199px 0 47px 0;
  }

  .cul-dat-title h3 {
    font-size: 36px;
  }
  .cul-dat-title h2 {
    font-size: 36px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 11px;
  }
  .cul-dat-title p {
    font-size: 15px;
  }
  .culture-dating-area {
    padding: 49px 0;
  }

  .contact-need-title h2 {
    text-align: left !important;
    font-size: 25px !important;
    margin-bottom: 100px;
    margin-top: 10px;
  }

  .contact-need-area {
    padding: 39px 0 20px 0 !important;
  }

  .approach-area {
    padding: 0px 0 70px 0;
  }

  /*==== timeline==== */
  .approach-meddle img {
    width: 294px;
  }
  .approach-left h5 {
    font-size: 16px;
  }
  .approach-left p {
    font-size: 11px;
  }
  .time-in-1 img {
    width: 100px;
  }
  .time-in-m-2 img {
    width: 100px;
  }
  .time-in-1 {
    position: absolute;
    right: -74px;
    top: 34px;
  }

  .approach-left p {
    margin-bottom: -49px;
  }

  .time-in-1.time-in-m-2 {
    width: 59px;
    top: 64px;
  }

  .approach-right h5 {
    font-size: 16px;
  }

  .approach-right p {
    font-size: 11px;
  }
  .time-in-3 img {
    width: 100px;
  }
  .time-in-4 img {
    width: 100px;
  }
  .time-in-3 {
    position: absolute;
    left: -74px;
    top: 34px;
  }

  .approach-right p {
    font-size: 11px;
    margin-bottom: -51px;
  }

  .time-in-m-4 {
    width: 59px;
    top: 40px;
  }
}

/*
   ====================================
   Small Screen - Mobile
   ====================================
   */

@media screen and (min-width: 576px) and (max-width: 767px) {
  .menu-bar {
    display: block;
  }
  .menu-item {
    display: none;
  }
  .hero-area {
    background-image: url(../assets/websiteimg/hero-bg.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-position: top left;
    padding: 199px 0 157px 0;
    position: relative;
    background-size: cover;
  }
  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .container {
    padding: 0 15px;
  }
  .hero-img {
    position: inherit;
    margin-top: 116px;
  }
  .scroll {
    position: absolute;
    bottom: -24px;
  }
  .card-area {
    padding: 94px 0 60px;
    background-color: #000000;
  }
  .card-item {
    padding: 36px 26px;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 30px;
  }
  .card-item p br {
    display: none;
  }
  .business-area {
    padding: 70px 0 118px 0;
  }
  .business-title h3 {
    font-size: 58px;
  }
  .business-title h2 {
    font-size: 48px;
  }
  .business-dashboard {
    padding: 100px 0 61px 0;
  }
  .dashbord-img {
    text-align: center;
    margin-top: -183px;
  }
  .client-like-title p br {
    display: none;
  }
  .client-like-title p {
    font-size: 21px;
  }
  .client-like-area::after {
    content: "";
    width: 1000px;
    height: 600px;
    display: block;
    background-image: url(../assets/websiteimg/client-bg.webp);
    background-repeat: no-repeat;
    background-position: top;
    left: left;
    position: absolute;
    top: 0;
    right: -81px;
    z-index: -126;
  }
  .client-box-item {
    margin-bottom: 30px;
  }
  .coustomer-right h2 {
    font-size: 34px;
  }
  .coustomer-left {
    margin-bottom: 26px;
  }
  .client-like-area {
    padding: 60px 0;
  }
  .customer-area {
    padding: 70px 0;
  }
  .footer-item {
    margin-bottom: 30px;
  }
  .copyright-text {
    border-top: 2px solid #78ef30;
    margin-top: 30px;
    padding: 35px 0;
    display: block;
    text-align: center;
  }
  .copyright-text p {
    margin-bottom: 20px;
  }
  /*=== Features Pricing page start=== */
  .priceing-area {
    padding: 290px 0 70px 0;
    background-image: url(../assets/websiteimg/price-list-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .map-item-bg {
    padding: 20px 7px;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-title h4 {
    font-size: 36px;
  }
  .get-In-area {
    padding: 56px 0;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .h-contact {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .home-befor-line::before {
    display: none;
  }
  .home-befor-line::after {
    display: none;
  }
  .price-befor-line::before {
    display: none;
  }
  .price-befor-line::after {
    display: none;
  }
  .contact-befor-line::before {
    display: none;
  }
  .contact-befor-line::after {
    display: none;
  }

  .hero-img {
    position: inherit;
    margin-top: 116px;
  }

  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }

  .cul-dat-title h3 {
    font-size: 25px;
  }
  .cul-dat-title h2 {
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 11px;
  }
  .cul-dat-title p {
    font-size: 15px;
  }
  .culture-dating-area {
    padding: 49px 0;
  }

  .contact-need-title h2 {
    text-align: left !important;
    font-size: 25px !important;
    margin-bottom: 100px;
    margin-top: 10px;
  }

  .dating-item-area {
    text-align: center;
    padding: 2px 0;
  }

  .contact-need-area {
    padding: 39px 0 20px 0 !important;
  }

  .approach-area {
    padding: 0px 0 0px 0;
  }

  /*=== time-line==== */

  .time-in-1,
  .time-in-3 {
    display: none;
  }
  .approach-left h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-left p {
    text-align: center;
  }
  .approach-right h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-right p {
    text-align: center;
    color: #fff;
  }

  .approach-left {
    padding-top: 1px;
  }

  .cul-dat-title p {
    margin-bottom: 20px;
  }

  .approach-right {
    position: relative;
    padding-top: 5px;
  }

  .mobile-timeline-img {
    display: block !important;
  }

  .meddle-m-none {
    display: none;
  }

  .coustomer-left {
    padding-right: 0px !important;
  }
}

/*
   ====================================
   Xtra Small Screen - Small Mobile
   ====================================
   */

@media screen and (max-width: 576px) {
  .menu-bar {
    display: block;
  }
  .menu-item {
    display: none;
  }
  .hero-area {
    background-image: url(../assets/websiteimg/hero-bg.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-position: top left;
    padding: 120px 0 157px 0;
    position: relative;
    background-size: cover;
  }
  .hero-img {
    position: inherit;
    margin-top: 116px;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .container {
    padding: 0 15px;
  }
  .hero-img {
    position: inherit;
    margin-top: 44px;
  }
  .scroll {
    position: absolute;
    bottom: -24px;
  }
  .card-area {
    padding: 94px 0 60px;
    background-color: #000000;
  }
  .card-item {
    padding: 36px 26px;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 30px;
  }
  .card-item p br {
    display: none;
  }
  .business-area {
    padding: 70px 0 118px 0;
  }
  .business-title h3 {
    font-size: 58px;
  }
  .business-title h2 {
    font-size: 48px;
  }
  .business-dashboard {
    padding: 100px 0 61px 0;
  }
  .dashbord-img {
    text-align: center;
    margin-top: -183px;
  }
  .client-like-title p br {
    display: none;
  }
  .client-like-title p {
    font-size: 21px;
  }
  .client-like-area::after {
    content: "";
    width: 1000px;
    height: 600px;
    display: block;
    background-image: url(../assets/websiteimg/client-bg.webp);
    background-repeat: no-repeat;
    background-position: top;
    left: left;
    position: absolute;
    top: 0;
    right: -81px;
    z-index: -126;
  }
  .client-box-item {
    margin-bottom: 30px;
  }
  .coustomer-right h2 {
    font-size: 34px;
  }
  .coustomer-left {
    margin-bottom: 26px;
  }
  .client-like-area {
    padding: 60px 0;
  }
  .customer-area {
    padding: 70px 0;
  }
  .footer-item {
    margin-bottom: 30px;
  }
  .copyright-text {
    border-top: 2px solid #78ef30;
    margin-top: 30px;
    padding: 35px 0;
    display: block;
    text-align: center;
  }
  .copyright-text p {
    margin-bottom: 20px;
  }
  /*=== Features Pricing page start=== */
  .priceing-area {
    padding: 290px 0 70px 0;
    background-image: url(../assets/websiteimg/price-list-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .map-item-bg {
    padding: 20px 7px;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-title h4 {
    font-size: 36px;
  }
  .get-In-area {
    padding: 56px 0;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .h-contact {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .h-contact:hover {
    color: #fff !important;
    background-color: #66b634;
  }
  .home-befor-line::before {
    display: none;
  }
  .home-befor-line::after {
    display: none;
  }
  .price-befor-line::before {
    display: none;
  }
  .price-befor-line::after {
    display: none;
  }
  .contact-befor-line::before {
    display: none;
  }
  .contact-befor-line::after {
    display: none;
  }

  .hero-img {
    position: inherit;
    margin-top: 116px;
  }

  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }

  .cul-dat-title h3 {
    font-size: 25px;
  }
  .cul-dat-title h2 {
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 11px;
  }
  .cul-dat-title p {
    font-size: 15px;
  }
  .culture-dating-area {
    padding: 49px 0;
  }

  .contact-need-title h2 {
    text-align: left !important;
    font-size: 25px !important;
    margin-bottom: 100px;
    margin-top: 10px;
  }

  .dating-item-area {
    text-align: center;
    padding: 2px 0;
  }

  .contact-need-area {
    padding: 39px 0 20px 0 !important;
  }

  .approach-area {
    padding: 0px 0 0px 0;
  }

  /*=== time-line==== */

  .time-in-1,
  .time-in-3 {
    display: none;
  }
  .approach-left h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-left p {
    text-align: center;
  }
  .approach-right h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-right p {
    text-align: center;
    color: #fff;
  }

  .approach-left {
    padding-top: 1px;
  }

  .cul-dat-title p {
    margin-bottom: 20px;
  }

  .approach-right {
    position: relative;
    padding-top: 5px;
  }

  .mobile-timeline-img {
    display: block !important;
  }

  .meddle-m-none {
    display: none;
  }

  .customer-left {
    padding-right: 0 !important;
  }
}