@import "base";

.web-footer {
  padding: 10rem 0 1rem 0;
  background-color: $background-black;
}

.web-footer-item h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $accent-green;
  margin-bottom: 3rem;
}

.web-footer-item ul li {
  color: $font-colour;
  padding: 1rem 0;
  font-size: 1.6rem;
}

.web-footer-item ul li a {
  font-size: 1.4rem;
  font-weight: 400;
  color: $font-colour;
  display: inline-block;
  transition: 0.2s;
}

.web-footer-item ul li a:hover {
  color: $accent-green;
}

.web-footer-item ul li span {
  color: $accent-green;
  margin-right: 1rem;
}

.web-footer-item p a {
  font-size: 2rem;
  color: $font-colour;
  display: inline-block;
  padding: 0 2.7rem 0 0;
  transition: 0.2s;
}

.web-footer-item p a:hover {
  color: $accent-green;
  transform: scale(1.3);
}

.web-copyright-text {
  border-top: 0.2rem solid $accent-green;
  margin-top: 3rem;
  padding: 3.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web-copyright-text p {
  font-size: 1.4rem;
  font-weight: 600;
  color: $font-colour;
}

.web-copyright-text p span {
  font-weight: 900;
  color: $accent-green;
  text-transform: uppercase;
}

.web-rq-demo-btn a {
  font-size: 1.6rem;
  font-weight: 700;
  color: $background-black;
  display: inline-block;
  padding: 1rem 5rem;
  text-transform: uppercase;
  transition: 0.3s;
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  background-image: url(../assets/websiteimg/icons-top.png);
  background-repeat: no-repeat;
  background-position: 10% 50%;
}

.web-rq-demo-btn a:hover {
  background-color: $hover-btn-green;
}