.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.sidebar:after,
.sidebar:before {
  background: unset !important;
  opacity: unset;
}
.sidebar .logo {
  border-bottom: unset !important;
}
.sidebar .logo-img img,
.navbar .logo-img img {
  width: 105px;
  top: 11px;
  position: absolute;
  left: 40px;
}
.main-panel .main-content {
  background: #000000;
}
.navbar-default .navbar-collapse {
  background: #000000;
}
.sidebar {
  background: #000000;
}
.sidebar .nav {
  margin-top: 75px;
}
.sidebar .nav li.active > a {
  background: unset;
}
.sidebar .nav li > a:hover {
  background: unset;
}
.sidebar .nav p {
  text-align: left;
  font: normal normal 300 14px/32px Nunito Sans;
  letter-spacing: 0.14px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.navbar .navbar-brand {
  font: normal normal normal 24px/22px Nunito Sans;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}
.language-txt {
  font: normal normal 300 14px/19px Nunito Sans;
  letter-spacing: 0.14px;
  color: #ffffff !important;
  opacity: 1;
  position: relative;
  left: 6px;
}
.down-arrow {
  position: relative;
  left: 30px;
  top: 2px;
}
.bell-icon {
  position: relative;
  left: 6px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: unset !important;
  color: #78ef30;
  opacity: 1;
  text-decoration: none !important;
}
.navbar-nav > li > .dropdown-menu {
  box-shadow: 0 0 10px 2px #78ef30;
  background: #302e2e;
  min-width: 30px;
  margin-top: -7px;
}
.dropdown-menu > li > a {
  padding: 2px 14px;
  color: #fff !important;
  font-size: 12px;
  opacity: 1;
}
.dropdown-menu > li > a {
  border-bottom: 1px solid dimgrey;
}
.industry-txt {
  font: normal normal normal 14px/26px Nunito Sans;
  letter-spacing: 0.14px;
  color: #78ef30 !important;
}

.navbar .navbar-nav > li > a {
  padding: 0 20px;
  margin: 13px 2px;
  position: relative;
}
.language-pos {
  position: relative;
  top: 6px;
}
.ball-pos {
  position: relative;
  top: 6px;
}
.side-hover i:hover {
  color: #78ef30;
}

.side-hover .icon-tag:hover > i {
  color: #78ef30;
}

.side-hover:hover > p {
  color: #78ef30;
}
.sidebar .nav li:hover > a {
  background: unset;
}

/*navbar syles bein here*/

.navbar-default,
.navbar-nav > li > a:not(.btn):hover,
.navbar-default .navbar-nav > li > a:not(.btn):focus {
  color: #9a9a9a !important;
}
.navbar-default,
.navbar-brand,
.navbar-default {
  color: #fff !important;
}

.navbar-default .navbar-nav > .dropdown > a:hover,
.navbar-default .navbar-nav > .dropdown > a:focus {
  border-top-color: #9a9a9a !important;
  border-bottom-color: #9a9a9a !important;
}

.img-profile {
  position: fixed;
}
.edit-profile {
  position: absolute;
  bottom: 0px;
  left: 40px;
}
.side-icons {
  width: 16px;
}

.navbar-container {
  width: 100%;
}

.navbar-default {
  border-color: #000000 !important;
  background-color: #000000 !important;
  border-bottom: 1px solid #9a9a9a !important;
}

.navbar-right {
  flex-direction: row;
}

.navbar-default .navbar-nav > .open > a {
  background-color: #00000000 !important;
}

.navbar-nav > .open > a:hover {
  background-color: #00000000 !important;
}

.navbar-nav > .open > a:focus {
  background-color: #00000000 !important;
}

.navbar-nav > li > .dropdown-menu.lang {
  position: absolute;
  top: 52px;
  right: 10px;
}

.navbar-nav > li > .dropdown-menu.profile {
  position: absolute;
  top: 56px;
  right: 10px;
}
@media screen and (max-width: 530px) {
  .navbar .navbar-nav > li > a {
    padding: 0 8px;
  }
}
