// -------------------------------  these need to be reviewed

@import "variables";
@import "mixins";
@import "misc";
@import "sidebar-and-main-panel";
@import "navbars";
@import "schedule";
@import "customer_artist";
.navbar {
  padding-left: 2rem;
  padding-right: 2rem;
}
.cdk-overlay-pane {
  overflow: auto;
}
