@import 'base';


.web-pricing-hero {
  background-image: url(../assets/websiteimg/price-bg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  padding: 0;
  height: calc( 100vh - 90px);
}

/* ===.price-box-area-start ==== */

.pricing-area {
  padding: 35rem 0 15rem 0;
  background-image: url(../assets/websiteimg/price-list-bg.webp);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.price-box {
  padding: 11rem 3.5rem 3.5rem 4.2rem;
  background-image: url(../assets/websiteimg/price-box.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  border-radius: 2rem;
}

.price-box p {
  font-size: 1.4rem;
  font-weight: 600;
  color: $accent-green;
  text-transform: uppercase;
  margin-top: 2rem !important;
}

.price-box p span {
  font-size: 7rem;
  font-weight: 800;
  color: $font-colour;
  padding-right: 1rem;
  display: inline-block;
}

.price-box ul {
  padding: 1rem 0;
  border-left: 0.2rem solid #484948;
  color: $font-colour;
}

.price-box ul li {
  padding: 0 0 1rem 0;
  margin-bottom: 1rem;
  position: relative;
  margin-left: 3rem;
}

.price-list {
  height: 30rem;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-left: 2rem;
  /* scrollbar-width: 1px; */
}

/* width */
.price-list::-webkit-scrollbar {
  width: 0.6rem;
}

/* Track */

.price-list::-webkit-scrollbar-track {
  background: $font-colour;
  border-radius: 5rem;
}

/* Handle */
.price-list::-webkit-scrollbar-thumb {
  background: $accent-green;
  border-radius: 5rem;
}

/* Handle on hover */

.price-list::-webkit-scrollbar-thumb:hover {
  background: $accent-green;
}

/* Track */
::-webkit-scrollbar-track {
  background: $fourth-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $accent-green;
  border-radius: 5rem;
}

.price-list-full-item {
  margin-top: -20rem;
}

.price-box ul li::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background-color: $accent-green;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0.5rem;
  left: -3.8rem;
}

// ------------------------------------- package headers

.price-tag h3 {
  font-size: 2.4rem !important;
  font-weight: 700 !important;
  color: $fourth-black;
  padding: 2rem 10rem 3rem 4rem !important;
  background-image: url(../assets/websiteimg/price-title.png);
  background-repeat: no-repeat;
  /* background-position: 20px; */
  background-size: 100% 100% !important;
  position: absolute !important;
  top: 2.5rem !important;
  left: -1.5rem !important;
}

// -------------------------------------- package buttons

.package-button {
  margin-top: 5rem;
}

.package-button a {
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: $background-black;
  font-size: 1.4rem;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.package-button a:hover {
  background-color: $hover-btn-green;
}