/*     General overwrite     */
body,
.wrapper{
    min-height: 100vh;
    position: relative;
}

// handles EN icons in tool bar
.fa{
    width: 18px;
    text-align: center;
}

.wrapper{
    position: relative;
    top: 0;
    height: 100vh;
}
