//-----------    handles layout of the sidenav and toolbar
@import "variables";
@import "mixins";
.sidebarOpen.sidebar {
  width: 0px;
  .sidebar-wrapper {
    width: 0px;
    .dropdown .dropdown-backdrop {
      display: none !important;
    }
  }
}
.sidebar,
.bootstrap-navbar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 5;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;

  .sidebar-wrapper {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
    width: 260px;
    z-index: 6;

    padding-bottom: 75px;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: -70;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  // ---------------- controls logo distance from sidenav items

  .logo {
    padding: $navbar-padding-a;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .logo-img {
    width: 30px;
    display: inline-block;
    max-height: 30px;
    margin-right: 10px;

    img {
      width: 35px;
      top: 11px;
      position: absolute;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    margin-top: 20px;
    float: none;

    li {
      > a {
        color: #ffffff;
        margin: 15px 15px;
        opacity: 0.86;
        border-radius: 4px;
        display: block;
      }

      &:hover > a {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
      }

      a.dropdown-toggle {
        background-color: transparent !important;
        outline: none;
      }

      &.separator {
        margin: 15px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        & + li {
          margin-top: 31px;
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      margin-left: 45px;
      color: $white-color;
    }

    i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      color: $white-color;
    }
  }
}

// --------------------------------------------------------------------------------------------------------
.main-panel.main-wrapper-without-sidebar {
  width: 100%;
}
.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  z-index: 2;
  float: right;
  width: $sidebar-width;
  min-height: 100%;
  overflow: auto;

  .main-content {
    padding: 15px;
    height: 95%;
    // min-height: calc(100% - 123px);
    // overflow-y: scroll;
  }

  .dash-wrapper {
    display: grid;
    grid-template-columns: 6fr 3fr;
    // grid-template-rows: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 0px;

    &__left {
    }

    &__right {
    }
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
  }
}

// keeps sidenav fixed and lets main panel move

.sidebar,
.main-panel,
.sidebar-wrapper,
.navbar {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.main-panel {
  max-height: 100%;
  // height: 100%;
}
// media query
@media screen and (max-width: 1192.5px) {
  .sidebar {
    position: fixed;
  }
  #main-panel-cs.main-panel {
    width: 100%;
  }
}
