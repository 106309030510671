// ----------------------------------  colour variables

$accent-green: #78ef30;
$hover-btn-green: #66b634;
$outline-green: #79ef3062;
$background-green: #18371d;
$font-colour: #fff;
$secondary-white: #ffffff;
$third-white: #ffff;
$fourth-white: #ddd;
$background-black: #000000;
$secondary-black: #484948;
$third-black: #1a1a1a;
$fourth-black: #000;

// --------------------------------- global styles

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: $fourth-black !important;
  color: $background-black !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

