/*                handles the styles of sidenav and toolbar                  */
/* @import 'node_modules/@angular/material/prebuilt-themes/indigo-pink.css';*/
@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    position: absolute;
  }
}
.apexcharts-menu-icon,
.apexcharts-reset-icon {
  display: none !important;
}
.all-icons [class*="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
}
.map {
  height: 280px !important;
}
.places-buttons .btn {
  margin-bottom: 30px;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.txt-footer {
  font: normal normal normal 14px/26px Nunito Sans;
  letter-spacing: 0.14px;
  color: #ffffff;
  padding-top: 16px;
}
.mat-dialog-container {
  transform: none;
  background: #302e2e 0 0 no-repeat padding-box !important;
  border-radius: 15px !important;
  opacity: 1 !important;
  display: block;
  padding: 25px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden !important;
  outline: 0;
  /* width: 550px !important; */
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.cdk-overlay-pane {
  // width: unset !important;
}

/* Add Button style */
.add-btn {
  background: #78ef30 0 0 no-repeat padding-box !important;
  box-shadow: 0 0 10px #78ef30 !important;
  border-radius: 17px !important;
  opacity: 1;
  border: unset !important;
  font: normal normal normal 12px/18px Nunito Sans !important;
  letter-spacing: 0.12px;
  color: #000000 !important;
  opacity: 1;
  height: 32px;
}

/* listing  table style */

table {
  width: 100%;
}
.table-box {
  box-shadow: unset;
  padding: 36px;
  background: #302e2e 0 0 no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
}
.table-heading {
  font: normal normal bold 18px/24px Nunito Sans;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
}
.listing-table table tr td:first-child span {
  border-radius: 10px 0 0 10px;
}
.listing-table table tr td:last-child span {
  border-radius: 0 10px 10px 0;
}
.listing-table table tr td span {
  color: #fff;
  display: block;
  width: 100%;
  margin: 2px 0;
  padding: 13px 30px;
  background-color: #434343;
  font: normal normal 300 12px/16px Nunito Sans;
  letter-spacing: 0.12px;
  color: #ffffff;
  opacity: 1;
}
.listing-table th {
  color: white;
  font-size: 12px;
  font-weight: 400;
  color: #78ef30;
  padding: 10px 30px;
}
.up-down {
  width: 10px;
  margin-left: 10px;
}
.expend {
  width: 3px;
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-color: #78ef30;
  color: #000;
  border-color: unset;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.open > .btn.dropdown-toggle {
  background-color: #78ef30;
  color: #000;
  border-color: unset;
}

/*mobile views*/
@media screen and (max-device-width: 640px) {
  .table-box {
    overflow: scroll;
  }
  .cell-no {
    overflow: hidden;
    height: 42px;
  }
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border-radius: 10px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    background: #78ef30;
    border-radius: 30px;
  }
}
.drop-styl {
  box-shadow: 0 0 10px 2px #78ef30 !important;
  background: #302e2e !important;
  min-width: 30px !important;
  margin-top: -7px !important;
}
.drop-styl li {
  display: flex !important;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb:vertical {
  background: #78ef30;
  border-radius: 30px;
}
.search-styl {
  background: unset;
  color: #fff;
  padding: 8px;
  border: 1px solid #737373;
  border-radius: 20px;
  opacity: 1;
  position: relative;
  right: 16px;
}

.ngx-pagination {
  height: 32px;
}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #78ef30 !important;
  /*box-shadow: 0px 0px 10px 3px #78ef30;*/
  color: #000 !important;
  color: #fefefe;
  text-align: center;
  border-radius: 18px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  height: default;
}
.ngx-pagination span {
  position: relative;
  top: 2px;
}
.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: unset !important;
}
.ngx-pagination a,
.ngx-pagination button {
  cursor: pointer;
  color: gray !important;
  position: relative;
  top: 2px;
}
.pagination-pos {
  float: right;
  position: relative;
  top: 12px;
}

.close-button {
  border: 1px solid #78ef30;
  color: #78ef30;
  background-color: #ffffff00;
  padding: 0.5rem 1rem;
  border-radius: 14px;
  font-size: 12px;
}

.mat-dialog-container {
  // min-width: 750px !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #78ef30 !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #78ef30 !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: white !important;
}

h5.label {
  color: #78ef30;
  font-weight: 400;
  padding: 0.5rem 0rem !important;
}

.value {
  color: white;
  font-weight: 400;
}

app-client-appointment-detail,
app-appointment-detail {
  .mat-radio-button {
    margin: 0px 10px 0px 0px;
  }
  .mat-radio-label-content {
    font-size: 12px;
  }

  h1 {
    color: white;
    font-size: 18px !important;
    font-weight: bold;
    margin: 0rem 0rem;
  }

  img {
    width: 72px;
    height: 72px;
    margin: 20px 20px 0px 0px;
  }

  .state-wrapper {
    span {
      margin-right: 2rem;
      color: white;
    }
  }

  .state {
    height: 15px;
    width: 15px;
    border: 1px solid white;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .selected {
    border: none;
    box-shadow: 0 0 10px #78ef30;
    background-color: #78ef30;
  }
}

app-client-session-history,
app-artist-session-history {
  h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #ffffff;
  }

  .session-table table tr td:first-child span {
    border-radius: 10px 0 0 10px;
  }
  .session-table table tr td:last-child span {
    border-radius: 0 10px 10px 0;
  }
  .session-table table tr td span {
    color: #fff;
    display: block;
    width: 100%;
    margin: 2px 0;
    padding: 13px 30px;
    background-color: #434343;
    font: normal normal 300 12px/16px Nunito Sans;
    letter-spacing: 0.12px;
    color: #ffffff;
    opacity: 1;
  }
  .session-table th {
    color: white;
    font-size: 12px;
    font-weight: 400;
    color: #78ef30;
    padding: 10px 30px;
  }

  .session-table {
    padding: 0px;
  }
}
