@import "base";

.web-contact-hero {
  background-image: url(../assets/websiteimg/contact-bg.webp) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  padding: 0;
  height: calc(100vh - 90px);
}

.contact-left-text h2 span {
  color: $accent-green;
  text-transform: capitalize !important;
}

.contact-left-text h2 {
  font-size: 5.5rem !important;
  font-weight: 900 !important;
  margin-bottom: 0rem !important;
}

/* get - In - contact - area */

.get-In-area {
  padding: 15rem 0;
}

.form-title h4 {
  font-size: 5rem !important;
  font-weight: 700 !important;
  color: $accent-green;
  text-align: center;
}

.form-title h2 {
  font-size: 5.5rem !important;
  font-weight: 700 !important;
  color: $font-colour;
  text-align: center;
  text-transform: uppercase;
  margin: 5.4rem 0rem !important;
}

.form-area input,
.form-area textarea {
  font-size: 1.6rem;
  font-weight: 400;
  color: $font-colour;
  display: inline-block;
  padding: 2.1rem 1.6rem;
  border: 0.1rem solid $accent-green;
  background-color: $background-black;
  border-radius: 0 2rem 2rem 2rem;
  width: 100%;
  margin-bottom: 3rem;
  outline: none;
}

.form-area input::placeholder {
  color: $font-colour;
  opacity: 1;
}

.form-area textarea::placeholder {
  color: $font-colour;
  opacity: 1;
}

.get-submit {
  text-align: center;
}

.get-submit button {
  font-size: 1.4rem;
  font-weight: 600;
  color: $fourth-black;
  background-color: $accent-green;
  border-radius: 0 2rem 2rem 2rem;
  padding: 1.2rem 6rem;
  border: none;
  transition: 0.2s;
  text-transform: uppercase;
  margin-top: 3rem;
}

.get-submit button:hover {
  background-color: $hover-btn-green;
}

/* .-- map-area-start -- */

.map-area {
  padding: 3rem 0;
}

.map-item-bg {
  background-image: url(../assets/websiteimg/Map.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 5rem;
}

.map-item {
  background-image: url(../assets/websiteimg/map-item.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 13rem 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
}

.map-item h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $accent-green;
  margin-bottom: 3rem;
  text-align: center;
}

.map-item ul li {
  color: $font-colour;
  padding: 1rem 0;
  text-align: left;
  font-weight: 600;
}

.map-item ul li span {
  color: $accent-green;
  margin-right: 1rem;
}

.map-item p a {
  font-size: 2rem;
  color: $font-colour;
  display: inline-block;
  padding: 0 2.7rem 0 0;
  transition: 0.2s;
}

.map-item p a:hover {
  color: $accent-green;
  transform: scale(1.3);
}

.white-line {
  background-color: #ffffff;
  margin: 0.25rem 0;
  height: 1px;
  opacity: .6;
  margin-left: 1.5rem;
  &__short {
    width: 200px;
  }

  &__long {
    width: 250px;
    margin-top: 14px;
  }
}

.contact-before-line {
  margin-bottom: 0rem !important;
}

/* contact-page-end */
