*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

.web-Pricing-hero h2:last-of-type {
  color: #78ef30 !important;
}

.web-aboutus-hero {
  background-image: url(../assets/websiteimg/contact-bg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  height: calc(100vh - 90px);
}

.contact-left-text h2 {
  color: white !important;
  text-transform: capitalize !important;
  margin-bottom: 11rem !important;
}

.contact-left-text h2 span {
  color: #fff;
  text-transform: capitalize !important;
}

.culture-dating-area {
  padding: 10rem 0;
}

.cul-dat-title h3 {
  font-size: 5rem !important;
  font-weight: 900 !important;
  color: #78ef30;
  text-align: center;
}

.cul-dat-title h2 {
  font-size: 5.5rem !important;
  font-weight: 900 !important;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin: 4rem 0rem !important;
}

.cul-dat-title p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin: 6rem 0rem !important;
}

.dating-item-area {
  text-align: center;
  padding: 5rem 0;
}

.dating-item {
  margin-bottom: 2rem;
}

.contact-need-area {
  padding: 12rem 0 2rem 0;
  background-image: url(../assets/websiteimg/contact-need-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 886px;
}

.contact-need-title h2 {
  text-align: left !important;
  font-size: 5.5rem !important;
  margin: 3rem 0 !important;
  text-transform: uppercase;
  color: white;
  line-height: 5.5rem !important;
  font-weight: 900 !important;
}

.contact-need-title h3 {
  text-align: left !important;
  font-size: 5rem !important;
  color: #78ef30;
  font-weight: 900 !important;
}

.approach-area {
  padding: 0 0 7rem 0;
}

.approach-left {
  padding-top: 6rem !important;
  position: relative !important;
}

.approach-left h5 {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  font-weight: 700;
  color: #78ef30;
  margin-bottom: 1.5rem;
  text-align: right;
  margin-top: 1.5rem;
}

.approach-left p {
  font-size: 1.5rem;
  line-height: 2.5rem !important;
  font-weight: 500;
  text-align: right;
  margin-bottom: 4rem;
  color: #fff;
  margin: 3rem 0rem !important;
}

.time-in-1 {
  position: absolute;
  right: -16rem;
  top: -2rem;
  z-index: 1;
}

.time-in-m-2 {
  top: 42px;
}

.approach-right {
  position: relative;
  padding-top: 6rem;
}

.approach-right h5 {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  font-weight: 700;
  color: #78ef30;
  margin-bottom: 1.5rem;
  text-align: left;
  margin-top: 1.5rem;
}

.approach-right p {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  margin: 3rem 0rem !important;
  color: #fff;
  line-height: 2.5rem;
}

.time-in-3 {
  position: absolute;
  left: -16rem;
  top: -2rem;
}

.time-in-m-4 {
  top: 42px;
}

.mobile-timeline-img {
  display: none;
  text-align: center;
}

.mobile-timeline-img img {
  width: 12rem;
}

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

.web-hero-area {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 50%, rgba(255, 255, 255, 0) 100%), url(../assets/websiteimg/hero_banner.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  padding: 12rem 0 30rem 0;
  position: relative;
  height: calc(100vh - 90px);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

.web-hero-left-text h2 {
  font-size: 5.5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 5rem !important;
  line-height: 6rem !important;
}
.web-hero-left-text .white-text {
  color: #fff;
  text-transform: capitalize;
  margin-left: 0.5rem;
}

.web-hero-left-text h2:first-of-type {
  font-size: 5.5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 1rem !important;
}

.web-hero-left-text h2:last-of-type {
  font-size: 5.5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 5rem !important;
  color: #78ef30;
}

.web-hero-left-text h2 span {
  color: #78ef30;
  text-transform: uppercase;
}

.web-hero-left-text p {
  font-size: 2.4rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5rem !important;
  line-height: 3.4rem;
}

.web-hero-left-text a {
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.web-hero-left-text a:hover {
  background-color: #66b634;
}

.scroll {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.web-hero-right-item {
  position: relative !important;
}

/* ====offcanvas===== */
.offcanvas-body {
  background-color: #000000;
  color: #fff;
}

.offcanvas-header {
  background-color: #18371d;
  z-index: 1111;
}

.offcanvas-header h5 img {
  max-width: 20rem;
}

.clloss-x {
  font-size: 2.5rem;
  cursor: pointer;
  color: #fff;
}

.clloss-x:hover {
  color: #fff !important;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 33.3rem;
  z-index: 11111111111111;
}

.web-mobile-menu-item ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  color: #fff;
  display: inline-block;
  padding: 1rem 3rem;
  transition: 0.2s;
}

.web-mobile-menu-item ul li a:hover {
  color: #78ef30 !important;
}

.web-mobile-login a {
  display: block;
  text-align: center;
  background-position: 35% 50%;
  z-index: 1111;
  margin-top: 1rem;
}

.web-card-item {
  padding: 3.6rem 2.6rem;
  border-radius: 2rem;
  background-color: #1a1a1a;
}

.web-card-area {
  padding: 13rem 0;
  background-color: #000000;
}

.integrated-card-area {
  padding: 8rem 0;
  background-color: #000000;
}

.web-card-item {
  padding: 3.6rem 2.6rem;
  border-radius: 2rem;
  background-color: #1a1a1a;
}

.web-card-item img {
  margin-bottom: 1.5rem;
}

.web-card-item h2 {
  font-size: 2.6rem;
  font-weight: 700;
  color: #78ef30;
  margin-bottom: 2rem;
}

.web-card-item p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
}

.web-h-contact:hover {
  color: #fff !important;
  background-color: #66b634;
}

.web-business-area {
  padding: 10rem 0 30rem 0;
  background-image: url(../assets/websiteimg/business-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.web-business-title {
  text-align: center;
}

.web-business-title h3 {
  font-size: 5rem !important;
  font-weight: 900 !important;
  color: #78ef30;
}

.web-business-title h2 {
  font-size: 5.5rem !important;
  font-weight: 900 !important;
  color: #fff;
  margin: 4rem 0 !important;
  text-transform: uppercase;
  line-height: 5.5rem !important;
}

.web-business-title a {
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.web-business-title a:hover {
  background-color: #66b634;
}

.web-business-dashboard {
  padding: 10rem 0;
}

.web-dashboard-img {
  text-align: center;
  margin-top: -35rem;
}

.web-client-like-area {
  padding: 10rem 0;
  position: relative;
  z-index: 111;
  overflow: hidden;
}

.web-client-like-title p {
  font-size: 4.2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5rem;
  line-height: 1.5;
}

.web-client-like-title p span {
  color: #78ef30;
}

.web-client-box-item {
  background-image: url(../assets/websiteimg/s.webp);
  background-repeat: no-repeat;
  padding: 15rem 3.8rem;
  background-position: 100% 100%;
  background-size: 100% 100%;
  text-align: center;
  height: 100%;
}

.web-client-box-item h3 {
  font-size: 2.6rem;
  font-weight: 700;
  color: #78ef30;
  margin-bottom: 2rem;
}

.web-client-box-item p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
}

.web-client-like-area::after {
  content: "";
  width: 100rem;
  height: 60rem;
  display: block;
  background-image: url(../assets/websiteimg/client-bg.webp);
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -111;
}

.web-customer-area {
  padding: 12rem 0;
  background-image: url(../assets/websiteimg/coustomar-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.web-customer-right h2 {
  font-size: 5rem !important;
  font-weight: 700;
  color: #fff;
  margin-bottom: 3.6rem !important;
}

.web-customer-right h2 span {
  color: #78ef30;
  text-transform: uppercase;
  font-weight: 900 !important;
  line-height: 6rem;
}

.web-customer-right p {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 3rem;
  color: #fff;
  margin-bottom: 5rem !important;
}

.web-customer-right ul {
  padding: 1rem 0;
  border-left: 0.2rem solid #484948;
  margin-bottom: 4rem;
}

.web-customer-right ul li {
  font-size: 1.6rem;
  font-weight: 600;
  color: #ffffff;
  margin-left: 3rem;
  position: relative;
  /* line-height: 0px; */
  margin-bottom: 2rem;
}

.web-customer-right ul li:nth-child(3) {
  margin-bottom: 0 !important;
}

.web-customer-right ul li::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #78ef30;
  outline: 0.5rem solid rgba(121, 239, 48, 0.3843137255);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0.5rem;
  left: -3.6rem;
}

.web-customer-right a {
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.web-customer-right a:hover {
  background-color: #66b634;
}

@media screen and (max-width: 767.5px) {
  .web-hero-right-item {
    display: none;
  }
  .web-hero-area {
    padding: 12rem 0 3rem 0;
  }
  .web-card-area {
    padding: 5rem 0;
  }
  .integrated-card-area {
    padding: 5rem 0;
  }
}
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

.web-pricing-hero {
  background-image: url(../assets/websiteimg/price-bg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  padding: 0;
  height: calc(100vh - 90px);
}

/* ===.price-box-area-start ==== */
.pricing-area {
  padding: 35rem 0 15rem 0;
  background-image: url(../assets/websiteimg/price-list-bg.webp);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.price-box {
  padding: 11rem 3.5rem 3.5rem 4.2rem;
  background-image: url(../assets/websiteimg/price-box.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  border-radius: 2rem;
}

.price-box p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #78ef30;
  text-transform: uppercase;
  margin-top: 2rem !important;
}

.price-box p span {
  font-size: 7rem;
  font-weight: 800;
  color: #fff;
  padding-right: 1rem;
  display: inline-block;
}

.price-box ul {
  padding: 1rem 0;
  border-left: 0.2rem solid #484948;
  color: #fff;
}

.price-box ul li {
  padding: 0 0 1rem 0;
  margin-bottom: 1rem;
  position: relative;
  margin-left: 3rem;
}

.price-list {
  height: 30rem;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-left: 2rem;
  /* scrollbar-width: 1px; */
}

/* width */
.price-list::-webkit-scrollbar {
  width: 0.6rem;
}

/* Track */
.price-list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5rem;
}

/* Handle */
.price-list::-webkit-scrollbar-thumb {
  background: #78ef30;
  border-radius: 5rem;
}

/* Handle on hover */
.price-list::-webkit-scrollbar-thumb:hover {
  background: #78ef30;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #78ef30;
  border-radius: 5rem;
}

.price-list-full-item {
  margin-top: -20rem;
}

.price-box ul li::before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background-color: #78ef30;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0.5rem;
  left: -3.8rem;
}

.price-tag h3 {
  font-size: 2.4rem !important;
  font-weight: 700 !important;
  color: #000;
  padding: 2rem 10rem 3rem 4rem !important;
  background-image: url(../assets/websiteimg/price-title.png);
  background-repeat: no-repeat;
  /* background-position: 20px; */
  background-size: 100% 100% !important;
  position: absolute !important;
  top: 2.5rem !important;
  left: -1.5rem !important;
}

.package-button {
  margin-top: 5rem;
}

.package-button a {
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.package-button a:hover {
  background-color: #66b634;
}

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

.web-contact-hero {
  background-image: url(../assets/websiteimg/contact-bg.webp) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  padding: 0;
  height: calc(100vh - 90px);
}

.contact-left-text h2 span {
  color: #78ef30;
  text-transform: capitalize !important;
}

.contact-left-text h2 {
  font-size: 5.5rem !important;
  font-weight: 900 !important;
  margin-bottom: 0rem !important;
}

/* get - In - contact - area */
.get-In-area {
  padding: 15rem 0;
}

.form-title h4 {
  font-size: 5rem !important;
  font-weight: 700 !important;
  color: #78ef30;
  text-align: center;
}

.form-title h2 {
  font-size: 5.5rem !important;
  font-weight: 700 !important;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin: 5.4rem 0rem !important;
}

.form-area input,
.form-area textarea {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  padding: 2.1rem 1.6rem;
  border: 0.1rem solid #78ef30;
  background-color: #000000;
  border-radius: 0 2rem 2rem 2rem;
  width: 100%;
  margin-bottom: 3rem;
  outline: none;
}

.form-area input::placeholder {
  color: #fff;
  opacity: 1;
}

.form-area textarea::placeholder {
  color: #fff;
  opacity: 1;
}

.get-submit {
  text-align: center;
}

.get-submit button {
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  padding: 1.2rem 6rem;
  border: none;
  transition: 0.2s;
  text-transform: uppercase;
  margin-top: 3rem;
}

.get-submit button:hover {
  background-color: #66b634;
}

/* .-- map-area-start -- */
.map-area {
  padding: 3rem 0;
}

.map-item-bg {
  background-image: url(../assets/websiteimg/Map.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 5rem;
}

.map-item {
  background-image: url(../assets/websiteimg/map-item.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 13rem 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
}

.map-item h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #78ef30;
  margin-bottom: 3rem;
  text-align: center;
}

.map-item ul li {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-weight: 600;
}

.map-item ul li span {
  color: #78ef30;
  margin-right: 1rem;
}

.map-item p a {
  font-size: 2rem;
  color: #fff;
  display: inline-block;
  padding: 0 2.7rem 0 0;
  transition: 0.2s;
}

.map-item p a:hover {
  color: #78ef30;
  transform: scale(1.3);
}

.white-line {
  background-color: #ffffff;
  margin: 0.25rem 0;
  height: 1px;
  opacity: 0.6;
  margin-left: 1.5rem;
}
.white-line__short {
  width: 200px;
}
.white-line__long {
  width: 250px;
  margin-top: 14px;
}

.contact-before-line {
  margin-bottom: 0rem !important;
}

/* contact-page-end */
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

.web-footer {
  padding: 10rem 0 1rem 0;
  background-color: #000000;
}

.web-footer-item h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #78ef30;
  margin-bottom: 3rem;
}

.web-footer-item ul li {
  color: #fff;
  padding: 1rem 0;
  font-size: 1.6rem;
}

.web-footer-item ul li a {
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  transition: 0.2s;
}

.web-footer-item ul li a:hover {
  color: #78ef30;
}

.web-footer-item ul li span {
  color: #78ef30;
  margin-right: 1rem;
}

.web-footer-item p a {
  font-size: 2rem;
  color: #fff;
  display: inline-block;
  padding: 0 2.7rem 0 0;
  transition: 0.2s;
}

.web-footer-item p a:hover {
  color: #78ef30;
  transform: scale(1.3);
}

.web-copyright-text {
  border-top: 0.2rem solid #78ef30;
  margin-top: 3rem;
  padding: 3.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web-copyright-text p {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.web-copyright-text p span {
  font-weight: 900;
  color: #78ef30;
  text-transform: uppercase;
}

.web-rq-demo-btn a {
  font-size: 1.6rem;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 1rem 5rem;
  text-transform: uppercase;
  transition: 0.3s;
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  background-image: url(../assets/websiteimg/icons-top.png);
  background-repeat: no-repeat;
  background-position: 10% 50%;
}

.web-rq-demo-btn a:hover {
  background-color: #66b634;
}

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  box-sizing: border-box !important;
  font-family: nunito, sans-serif !important;
  font-weight: 400 !important;
  background: #000 !important;
  color: #000000 !important;
  font-size: 16px;
}

.container {
  max-width: 126rem;
}

.row {
  --bs-gutter-x: 3rem;
}

a,
button {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

a,
a:hover {
  text-decoration: none !important;
}

ul {
  list-style-type: none !important;
  padding: 0;
  margin: 0;
}

label {
  margin-bottom: 0;
}

.scroll-to-top {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem 2rem 0 0;
  background: #78ef30;
  display: block;
  text-align: center;
  padding-top: 0.8rem;
  font-size: 2.2rem;
  color: #ffffff;
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  z-index: 99999999;
}

.scroll-to-top:hover {
  background: #78ef30;
  color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

/* nav area */
.web-header {
  /*     position: fixed;
    width: 100%; */
  padding: 2.5rem 0 1.5rem 0;
  background-color: #000000;
  z-index: 1111111111111111;
  position: relative;
}

.web-header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.web-header .web-menu-item ul li a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  padding: 1rem 5rem;
  text-transform: uppercase;
  transition: 0.3s;
  position: relative;
}

.web-menu-item ul li a:hover {
  color: #78ef30;
}

.web-menu-item ul li a::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #78ef30;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -3rem;
  border-radius: 50%;
  opacity: 0;
  transition: 0.2s;
}

.web-menu-item ul li a:hover::before {
  opacity: 1;
}

.web-menu-bar {
  display: none;
}

.web-menu-bar a {
  font-size: 3rem;
  color: #78ef30;
  cursor: pointer;
}

.web-h-contact {
  background-color: #78ef30;
  border-radius: 0 2rem 2rem 2rem;
  transition: 0.2s;
  padding: 1rem 4rem;
}

.web-h-contact:hover {
  color: #fff !important;
  background-color: #66b634;
}

.web-menu-logo {
  position: relative;
}

.web-menu-logo {
  position: absolute;
  top: 2rem;
  left: 0;
}

.web-menu-logo img {
  max-width: 15rem;
}

.logos {
  visibility: hidden;
}

#active {
  color: #78ef30;
}

#active::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #78ef30;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -25px;
  border-radius: 50%;
  opacity: 1;
  transition: 0.2s;
  transform: translate(-50%, -50%);
}

.active {
  color: #78ef30 !important;
}

.web-h-contact::before {
  content: "";
  display: none !important;
}

.web-menu-item ul li a.active:before,
.web-mobile-menu-item ul li a.active:before {
  opacity: 1;
}

.web-header .home-before-line {
  position: relative;
}

.web-header .home-before-line::before {
  content: "";
  width: 23rem;
  height: 0.1rem;
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: 3.3rem;
  left: 38rem;
}

.web-header .home-before-line::after {
  content: "";
  width: 15rem;
  height: 0.1rem;
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: 4.3rem;
  left: 38rem;
}

.web-header .contact-before-line {
  position: relative;
}

.web-header .contact-before-line::before {
  content: "";
  width: 23rem;
  height: 0.1rem;
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: 3.3rem;
  left: 35rem;
}

.web-header .contact-before-line::after {
  content: "";
  width: 15rem;
  height: 0.1rem;
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: 4.3rem;
  left: 35rem;
}

.web-header .price-before-line {
  position: relative;
}

.web-header .price-before-line::before {
  content: "";
  width: 23rem;
  height: 0.1rem;
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: 3.5rem;
  left: 18.5rem;
}

.web-header .price-before-line::after {
  content: "";
  width: 15rem;
  height: 0.1rem;
  background-color: #ffffff;
  display: block;
  position: absolute;
  top: 4.5rem;
  left: 18.5rem;
}

.web-header .bar {
  width: 3rem;
  cursor: pointer;
}

.web-header .bar span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #78ef30;
  border-radius: 1rem;
  margin: 0.6rem 0;
}

/*
====================================
Medium Screen - Others
====================================
*/
@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .container {
    max-width: 1120px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-img img {
    max-width: 372px;
  }
  /*=== Features Pricing page start=== */
  .price-box p span {
    font-size: 54px;
  }
  .price-box {
    padding: 111px 20px 43px 20px;
  }
  .client-like-title p br {
    display: none;
  }
  .approach-area {
    padding-top: 0;
  }
  body {
    overflow-x: hidden;
  }
}
/*
   ====================================
   Small Screen - Tablate
   ====================================
   */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .menu-bar {
    display: block;
  }
  .menu-item {
    display: none;
  }
  .hero-area {
    background-image: url(../assets/websiteimg/hero-bg.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-position: top left;
    padding: 199px 0 157px 0;
    position: relative;
    background-size: cover;
  }
  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .container {
    padding: 0 15px;
  }
  .hero-img {
    position: inherit;
    margin-top: 44px;
  }
  .scroll {
    position: absolute;
    bottom: -24px;
  }
  .card-area {
    padding: 94px 0 60px;
    background-color: #000000;
  }
  .card-item {
    padding: 36px 26px;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 30px;
  }
  .card-item p br {
    display: none;
  }
  .business-area {
    padding: 70px 0 118px 0;
  }
  .business-title h3 {
    font-size: 58px;
  }
  .business-title h2 {
    font-size: 48px;
  }
  .business-dashboard {
    padding: 100px 0 61px 0;
  }
  .dashbord-img {
    text-align: center;
    margin-top: -183px;
  }
  .client-like-title p br {
    display: none;
  }
  .client-like-title p {
    font-size: 21px;
  }
  .client-like-area::after {
    content: "";
    width: 1000px;
    height: 600px;
    display: block;
    background-image: url(../assets/websiteimg/client-bg.webp);
    background-repeat: no-repeat;
    background-position: top;
    left: left;
    position: absolute;
    top: 0;
    right: -81px;
    z-index: -126;
  }
  .client-box-item {
    margin-bottom: 30px;
  }
  .coustomer-right h2 {
    font-size: 34px;
  }
  .coustomer-left {
    margin-bottom: 26px;
  }
  .client-like-area {
    padding: 60px 0;
  }
  .customer-area {
    padding: 70px 0;
  }
  .footer-item {
    margin-bottom: 30px;
  }
  .copyright-text {
    border-top: 2px solid #78ef30;
    margin-top: 30px;
    padding: 35px 0;
    display: block;
    text-align: center;
  }
  .copyright-text p {
    margin-bottom: 20px;
  }
  /*=== Features Pricing page start=== */
  .priceing-area {
    padding: 290px 0 70px 0;
    background-image: url(../assets/websiteimg/price-list-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 60%;
    background-position: bottom;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .price-box p {
    font-size: 13px;
    font-weight: 600;
    color: #78ef30;
    text-transform: uppercase;
  }
  .price-box p span {
    font-size: 55px;
  }
  .map-item-bg {
    padding: 20px 7px;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-title h4 {
    font-size: 36px;
  }
  .get-In-area {
    padding: 56px 0;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .map-item {
    background-image: url(../assets/websiteimg/map-item.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 93px 35px;
  }
  .mobile-menu-item ul li a {
    display: block;
    margin-top: 15px;
  }
  .h-contact {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .home-befor-line::before {
    top: 24px;
    left: 250px;
  }
  .home-befor-line::after {
    top: 30px;
    left: 250px;
  }
  .price-befor-line::before {
    top: 24px;
    left: 130px;
  }
  .price-befor-line::after {
    top: 30px;
    left: 130px;
  }
  .contact-befor-line::before {
    top: 24px;
    left: 230px;
  }
  .contact-befor-line::after {
    top: 30px;
    left: 230px;
  }
  .footer-item ul li {
    color: #fff;
    padding: 10px 0;
    font-size: 12px;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .cul-dat-title h3 {
    font-size: 36px;
  }
  .cul-dat-title h2 {
    font-size: 36px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 11px;
  }
  .cul-dat-title p {
    font-size: 15px;
  }
  .culture-dating-area {
    padding: 49px 0;
  }
  .contact-need-title h2 {
    text-align: left !important;
    font-size: 25px !important;
    margin-bottom: 100px;
    margin-top: 10px;
  }
  .contact-need-area {
    padding: 39px 0 20px 0 !important;
  }
  .approach-area {
    padding: 0px 0 70px 0;
  }
  /*==== timeline==== */
  .approach-meddle img {
    width: 294px;
  }
  .approach-left h5 {
    font-size: 16px;
  }
  .approach-left p {
    font-size: 11px;
  }
  .time-in-1 img {
    width: 100px;
  }
  .time-in-m-2 img {
    width: 100px;
  }
  .time-in-1 {
    position: absolute;
    right: -74px;
    top: 34px;
  }
  .approach-left p {
    margin-bottom: -49px;
  }
  .time-in-1.time-in-m-2 {
    width: 59px;
    top: 64px;
  }
  .approach-right h5 {
    font-size: 16px;
  }
  .approach-right p {
    font-size: 11px;
  }
  .time-in-3 img {
    width: 100px;
  }
  .time-in-4 img {
    width: 100px;
  }
  .time-in-3 {
    position: absolute;
    left: -74px;
    top: 34px;
  }
  .approach-right p {
    font-size: 11px;
    margin-bottom: -51px;
  }
  .time-in-m-4 {
    width: 59px;
    top: 40px;
  }
}
/*
   ====================================
   Small Screen - Mobile
   ====================================
   */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .menu-bar {
    display: block;
  }
  .menu-item {
    display: none;
  }
  .hero-area {
    background-image: url(../assets/websiteimg/hero-bg.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-position: top left;
    padding: 199px 0 157px 0;
    position: relative;
    background-size: cover;
  }
  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .container {
    padding: 0 15px;
  }
  .hero-img {
    position: inherit;
    margin-top: 116px;
  }
  .scroll {
    position: absolute;
    bottom: -24px;
  }
  .card-area {
    padding: 94px 0 60px;
    background-color: #000000;
  }
  .card-item {
    padding: 36px 26px;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 30px;
  }
  .card-item p br {
    display: none;
  }
  .business-area {
    padding: 70px 0 118px 0;
  }
  .business-title h3 {
    font-size: 58px;
  }
  .business-title h2 {
    font-size: 48px;
  }
  .business-dashboard {
    padding: 100px 0 61px 0;
  }
  .dashbord-img {
    text-align: center;
    margin-top: -183px;
  }
  .client-like-title p br {
    display: none;
  }
  .client-like-title p {
    font-size: 21px;
  }
  .client-like-area::after {
    content: "";
    width: 1000px;
    height: 600px;
    display: block;
    background-image: url(../assets/websiteimg/client-bg.webp);
    background-repeat: no-repeat;
    background-position: top;
    left: left;
    position: absolute;
    top: 0;
    right: -81px;
    z-index: -126;
  }
  .client-box-item {
    margin-bottom: 30px;
  }
  .coustomer-right h2 {
    font-size: 34px;
  }
  .coustomer-left {
    margin-bottom: 26px;
  }
  .client-like-area {
    padding: 60px 0;
  }
  .customer-area {
    padding: 70px 0;
  }
  .footer-item {
    margin-bottom: 30px;
  }
  .copyright-text {
    border-top: 2px solid #78ef30;
    margin-top: 30px;
    padding: 35px 0;
    display: block;
    text-align: center;
  }
  .copyright-text p {
    margin-bottom: 20px;
  }
  /*=== Features Pricing page start=== */
  .priceing-area {
    padding: 290px 0 70px 0;
    background-image: url(../assets/websiteimg/price-list-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .map-item-bg {
    padding: 20px 7px;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-title h4 {
    font-size: 36px;
  }
  .get-In-area {
    padding: 56px 0;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .h-contact {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .home-befor-line::before {
    display: none;
  }
  .home-befor-line::after {
    display: none;
  }
  .price-befor-line::before {
    display: none;
  }
  .price-befor-line::after {
    display: none;
  }
  .contact-befor-line::before {
    display: none;
  }
  .contact-befor-line::after {
    display: none;
  }
  .hero-img {
    position: inherit;
    margin-top: 116px;
  }
  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .cul-dat-title h3 {
    font-size: 25px;
  }
  .cul-dat-title h2 {
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 11px;
  }
  .cul-dat-title p {
    font-size: 15px;
  }
  .culture-dating-area {
    padding: 49px 0;
  }
  .contact-need-title h2 {
    text-align: left !important;
    font-size: 25px !important;
    margin-bottom: 100px;
    margin-top: 10px;
  }
  .dating-item-area {
    text-align: center;
    padding: 2px 0;
  }
  .contact-need-area {
    padding: 39px 0 20px 0 !important;
  }
  .approach-area {
    padding: 0px 0 0px 0;
  }
  /*=== time-line==== */
  .time-in-1,
  .time-in-3 {
    display: none;
  }
  .approach-left h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-left p {
    text-align: center;
  }
  .approach-right h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-right p {
    text-align: center;
    color: #fff;
  }
  .approach-left {
    padding-top: 1px;
  }
  .cul-dat-title p {
    margin-bottom: 20px;
  }
  .approach-right {
    position: relative;
    padding-top: 5px;
  }
  .mobile-timeline-img {
    display: block !important;
  }
  .meddle-m-none {
    display: none;
  }
  .coustomer-left {
    padding-right: 0px !important;
  }
}
/*
   ====================================
   Xtra Small Screen - Small Mobile
   ====================================
   */
@media screen and (max-width: 576px) {
  .menu-bar {
    display: block;
  }
  .menu-item {
    display: none;
  }
  .hero-area {
    background-image: url(../assets/websiteimg/hero-bg.png);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-position: top left;
    padding: 120px 0 157px 0;
    position: relative;
    background-size: cover;
  }
  .hero-img {
    position: inherit;
    margin-top: 116px;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .container {
    padding: 0 15px;
  }
  .hero-img {
    position: inherit;
    margin-top: 44px;
  }
  .scroll {
    position: absolute;
    bottom: -24px;
  }
  .card-area {
    padding: 94px 0 60px;
    background-color: #000000;
  }
  .card-item {
    padding: 36px 26px;
    border-radius: 20px;
    background-color: #1a1a1a;
    margin-bottom: 30px;
  }
  .card-item p br {
    display: none;
  }
  .business-area {
    padding: 70px 0 118px 0;
  }
  .business-title h3 {
    font-size: 58px;
  }
  .business-title h2 {
    font-size: 48px;
  }
  .business-dashboard {
    padding: 100px 0 61px 0;
  }
  .dashbord-img {
    text-align: center;
    margin-top: -183px;
  }
  .client-like-title p br {
    display: none;
  }
  .client-like-title p {
    font-size: 21px;
  }
  .client-like-area::after {
    content: "";
    width: 1000px;
    height: 600px;
    display: block;
    background-image: url(../assets/websiteimg/client-bg.webp);
    background-repeat: no-repeat;
    background-position: top;
    left: left;
    position: absolute;
    top: 0;
    right: -81px;
    z-index: -126;
  }
  .client-box-item {
    margin-bottom: 30px;
  }
  .coustomer-right h2 {
    font-size: 34px;
  }
  .coustomer-left {
    margin-bottom: 26px;
  }
  .client-like-area {
    padding: 60px 0;
  }
  .customer-area {
    padding: 70px 0;
  }
  .footer-item {
    margin-bottom: 30px;
  }
  .copyright-text {
    border-top: 2px solid #78ef30;
    margin-top: 30px;
    padding: 35px 0;
    display: block;
    text-align: center;
  }
  .copyright-text p {
    margin-bottom: 20px;
  }
  /*=== Features Pricing page start=== */
  .priceing-area {
    padding: 290px 0 70px 0;
    background-image: url(../assets/websiteimg/price-list-bg.webp);
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;
  }
  .price-box {
    margin-bottom: 30px;
  }
  .map-item-bg {
    padding: 20px 7px;
  }
  .form-title h2 {
    font-size: 30px;
  }
  .form-title h4 {
    font-size: 36px;
  }
  .get-In-area {
    padding: 56px 0;
  }
  .contact-hero {
    padding: 199px 0 47px 0;
  }
  .h-contact {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .h-contact:hover {
    color: #fff !important;
    background-color: #66b634;
  }
  .home-befor-line::before {
    display: none;
  }
  .home-befor-line::after {
    display: none;
  }
  .price-befor-line::before {
    display: none;
  }
  .price-befor-line::after {
    display: none;
  }
  .contact-befor-line::before {
    display: none;
  }
  .contact-befor-line::after {
    display: none;
  }
  .hero-img {
    position: inherit;
    margin-top: 116px;
  }
  .hero-img {
    position: inherit;
  }
  .hero-left-text h2:first-of-type {
    font-size: 40px;
  }
  .hero-left-text h2:last-of-type {
    font-size: 40px;
  }
  .hero-left-text p br {
    display: none;
  }
  .hero-left-text p {
    font-size: 18px;
    margin-bottom: 39px;
  }
  .cul-dat-title h3 {
    font-size: 25px;
  }
  .cul-dat-title h2 {
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 11px;
  }
  .cul-dat-title p {
    font-size: 15px;
  }
  .culture-dating-area {
    padding: 49px 0;
  }
  .contact-need-title h2 {
    text-align: left !important;
    font-size: 25px !important;
    margin-bottom: 100px;
    margin-top: 10px;
  }
  .dating-item-area {
    text-align: center;
    padding: 2px 0;
  }
  .contact-need-area {
    padding: 39px 0 20px 0 !important;
  }
  .approach-area {
    padding: 0px 0 0px 0;
  }
  /*=== time-line==== */
  .time-in-1,
  .time-in-3 {
    display: none;
  }
  .approach-left h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-left p {
    text-align: center;
  }
  .approach-right h5 {
    font-size: 23px;
    text-align: center;
  }
  .approach-right p {
    text-align: center;
    color: #fff;
  }
  .approach-left {
    padding-top: 1px;
  }
  .cul-dat-title p {
    margin-bottom: 20px;
  }
  .approach-right {
    position: relative;
    padding-top: 5px;
  }
  .mobile-timeline-img {
    display: block !important;
  }
  .meddle-m-none {
    display: none;
  }
  .customer-left {
    padding-right: 0 !important;
  }
}